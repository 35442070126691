import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Mission({ styles, elements, ...props }) {
  const Elements = useComponents()

  const customFieldCount = elements.custom.length
  const stackedDisplay = customFieldCount > 1

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={{
        backgroundColor: styles.forElement("page").backgroundColor
      }}
    >
      <div className="formkit-background" style={styles.forElement("page")} />
      <div className="formkit-container">
        <div className="formkit-page-header">
          <Elements.Image
            className="formkit-image formkit-column"
            name="image"
            size={{ h: 900, w: 675 }}
          />
          <div className="formkit-column">
            <Elements.Heading
              name="header"
              defaults={{ content: "Enter a catchy page headline" }}
            />
            <Elements.Region name="subheader" className="formkit-subheader">
              <Elements.Content
                defaults={{
                  content: "Provide some more detail in this subtitle"
                }}
              />
            </Elements.Region>
          </div>
        </div>

        <div className="formkit-card" style={styles.forElement("content")}>
          <div className="formkit-card-top">
            <Elements.Heading
              tag="h2"
              className="formkit-content-header"
              name="content_header"
              defaults={{ content: "Describe your product" }}
            />
            <Elements.Region
              name="content_subheader"
              className="formkit-content-subheader"
            >
              <Elements.Content
                defaults={{
                  content: "Add detailed content here"
                }}
              />
            </Elements.Region>
          </div>
          <Elements.Form style={styles.forElement("form")}>
            <Elements.Heading
              tag="h2"
              className="formkit-form-header"
              name="form_header"
              defaults={{ content: "Join the newsletter" }}
            />
            <Elements.Region
              className="formkit-form-subheader"
              name="form_subheader"
            >
              <Elements.Content
                defaults={{
                  content:
                    "Join my newsletter You'll also receive free photography tips each week and be the first to hear when Mission Photography is available for purchase. Subscribe to start learning today."
                }}
              />
            </Elements.Region>
            <Elements.Errors />
            <Elements.CustomFields
              direction={stackedDisplay ? "vertical" : "horizontal"}
              data-stacked={stackedDisplay}
            >
              {stackedDisplay && <Elements.AddFieldButton />}
              <Elements.Button
                name="submit"
                group="button"
                defaults={{ content: "Subscribe" }}
              />
              {!stackedDisplay && <Elements.AddFieldButton />}
            </Elements.CustomFields>
            <Elements.Content
              name="disclaimer"
              defaults={{
                content: "We respect your privacy. Unsubscribe at any time."
              }}
            />
          </Elements.Form>
        </div>
      </div>
      <Elements.BuiltWith background="page" image="page" />
    </Elements.LandingPage>
  )
}

Mission.style = "landing_page"
Mission.categories = ["eBook"]
Mission.thumbnail = ""
Mission.preview = "https://demo.ck.page/mission"
Mission.fields = [
  {
    name: "first_name",
    label: "Your first name"
  },
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Mission, { name: "Mission" })
